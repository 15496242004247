<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">{{i18n('Custom.RemovePerson')}}</div>

          <div class="modal-body">
            <div class="alert-box">
              <div class="alert-icon">!</div>
              {{i18n('Custom.IsRemovePerson')}}
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{i18n('Basic.Cancel')}}
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="submit"
            >
              {{i18n('Basic.Delete')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-blue {
  color: #fff !important;
  font-size: 0.85em;
  background: #343b55;
}
</style>

<script>
export default {
  props: ['removePerson'],
  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    submit() {
      this.$emit('deletePerson', this.removePerson.UserId);
    },
  },
};
</script>
