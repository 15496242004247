<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="editModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <form class="modal-content" @submit.prevent="submitEdit">
          <div class="modal-header">{{i18n('Role.RoleInformation')}}</div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <div class="field">
                  <label class="field-name">{{i18n('Role.RoleName')}}</label>
                  <input
                    id="title"
                    type="text"
                    name="title"
                    value=""
                    v-model="text"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-blue">{{i18n('Basic.Save')}}</button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >{{i18n('Basic.Cancel')}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-blue {
  color: #fff !important;
  font-size: 0.85em;
  background: #343b55;
}
</style>

<script>
export default {
  props: ['editRole'],
  data() {
    return {
      text: '',
    };
  },
  watch: {
    editRole() {
      this.text = this.editRole.Name;
    },
  },
  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    submitEdit() {
      this.$emit('editRoleName', this.text);
      // eslint-disable-next-line no-undef
      $('#editModal').modal('hide');
    },
  },
};
</script>
