<template>
  <div class="roleTable">
    <div class="row">
      <div class="col-md-6">
        <div class="d-flex justify-content-end mb-2">
          <button
            class="btn btn-blue"
            data-toggle="modal"
            data-target="#editModal"
            @click="openModal(true)"
          >
            {{i18n('Role.AddRole')}}
          </button>
        </div>

        <div class="card">
          <div class="card-body">
            <!-- <div class="filter-block">
                            <div class="filter-field">
                                <span class="title">
                                    角色名稱
                                </span>
                                <input type="type" name="name" value="" />
                            </div>

                            <div class="filter-btn-wrap">
                                <button class="btn btn-blue" type="submit">查詢</button>
                            </div>
                        </div> -->

            <table class="table table-striped table-light table-hover">
              <thead>
                <tr>
                  <th>{{i18n('Basic.Select')}}</th>
                  <th class="text-center">{{i18n('Role.RoleName')}}</th>
                  <th class="text-center">{{i18n('Basic.Edit')}}</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="i in items" :key="i.id">
                  <td>
                    <label class="form-checkbox">
                      <input
                        type="radio"
                        name="role"
                        :value="i.Name"
                        v-model="choiceRole"
                        @click="choiceTarget(i.Id, i)"
                      />
                      <i class="form-icon"></i>
                    </label>
                  </td>
                  <td class="text-center">{{ i.Name }}</td>
                  <td class="text-center">
                    <div>
                      <button
                        class="btn btn-sm btn-blue mr-2"
                        data-toggle="modal"
                        data-target="#editModal"
                        v-if="
                          i.Name !== '一般使用者' &&
                          i.Name !== '管理者' &&
                          i.Name !== '簽核主管'&&
                          i.Name !== '面試人員'&&
                          i.Name !== '信件範本管理'
                        "
                        @click="openModal(false, i)"
                      >
                        {{i18n('Basic.Edit')}}
                      </button>
                      <button
                        class="btn btn-sm btn-primary mr-2"
                        data-toggle="modal"
                        data-target="#addpersonModal"
                        @click="getAllPerson(i.Name, i.Id, i)"
                      >
                        {{i18n('Role.SetUpEmployee')}}
                      </button>
                      <button
                        class="btn btn-sm btn-danger"
                        data-toggle="modal"
                        data-target="#deleteRoleModal"
                        v-if="
                          i.Name !== '一般使用者' &&
                          i.Name !== '管理者' &&
                          i.Name !== '簽核主管'&&
                          i.Name !== '面試人員'&&
                          i.Name !== '信件範本管理'
                        "
                        @click="openDeleteRole(i)"
                      >
                        {{i18n('Basic.Delete')}}
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-6 right" v-if="persons.length > 0">
        <div class="card">
          <div class="card-body person">
            <table class="table table-striped table-light table-hover">
              <thead>
                <tr>
                  <th>{{i18n('Basic.Department')}}</th>
                  <th>{{i18n('Custom.PersonnelNumber')}}</th>
                  <th>{{i18n('Basic.Employee')}}</th>
                  <th>{{i18n('Custom.Function')}}</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="i in persons" :key="i.id">
                  <!-- <td>
                                        <label class="form-checkbox">
                                            <input type="checkbox" :value="i.id" v-model="selected">
                                            <i class="form-icon"></i>
                                        </label>
                                    </td> -->
                  <!-- <td>{{i.id}}</td> -->
                  <td>{{ i.DeptSName }}</td>
                  <td>{{ i.EmpNo }}</td>
                  <td>{{ i.EmpCName }}</td>
                  <td>
                    <button
                      class="btn btn-sm btn-danger"
                      data-toggle="modal"
                      data-target="#deleteModal"
                      @click="openRemove(i)"
                    >
                      {{i18n('Custom.RemovePerson')}}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <PersonSelect @batchPerson="batchPerson" :selectPersons="persons" />
    <EditModal @editRoleName="editRoleName" :editRole="editRole" />
    <DeleteModal @deletePerson="deletePerson" :removePerson="removePerson" />

    <!-- DeleteRole Modal -->
    <div class="modal fade" id="deleteRoleModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">{{i18n('Role.DeleteRole')}}</div>

          <div class="modal-body">
            <div class="alert-box">
              <div class="alert-icon">!</div>
              {{i18n('Role.WhetherDeleteThisRole')}}
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{i18n('Basic.Cancel')}}
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="deleteRole"
            >
              {{i18n('Basic.Delete')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.filter-block .filter-btn-wrap .btn {
  line-height: 0;
}

.filter-block .filter-btn-wrap {
  margin-bottom: 0;
}

.btn-blue {
  color: #fff !important;
  font-size: 0.85em;
  background: #343b55;
  border-radius: 6px;
}

.filter-block .filter-field > input {
  border: solid 1px;
}

.btn-group {
  display: block;
  text-align: right;
}

.person {
  overflow-y: scroll;
  height: 500px;
}

.right {
  margin-top: 42px;
}
</style>

<script>
import { mapActions } from 'vuex';
import PersonSelect from './PersonSelect.vue';
import EditModal from './EditModal.vue';
import DeleteModal from './DeleteModal.vue';

export default {
  components: {
    PersonSelect,
    EditModal,
    DeleteModal,
  },
  data() {
    return {
      screen: null, // loading遮罩
      items: [
        // {
        //     id: 'id1',
        //     roleName: 'Admin'
        // },
        // {
        //     id: 'id2',
        //     roleName: 'IMO'
        // },
        // {
        //     id: 'id3',
        //     roleName: 'PLD'
        // },
      ],
      persons: [
        // {
        //     id: 'J005',
        //     name: 'Jason',
        //     branch: '行銷',
        // },
        // {
        //     id: 'J006',
        //     name: 'Eric',
        //     branch: '行銷',
        // },
        // {
        //     id: 'J007',
        //     name: 'Andy',
        //     branch: '行銷',
        // },
      ],
      choiceRole: '',
      choiceInfo: {},
      isNew: true,
      editRole: {},
      removePerson: {},
      removeRole: {},
      selected: [],
      selectAll: false,
    };
  },
  methods: {
    ...mapActions(['updateLoading']),
    ...mapActions('personModule', ['getPerson', 'getSetting', 'getOption']),
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    getAllData() {
      this.screen = this.openLoading();
      const vm = this;
      // const url = `http://site5.yahome-test.com.tw/auth/permission`;
      const url = `${window.BaseUrl.api}/role`;

      vm.$http.get(url).then((response) => {
        if (response.data.ErrorMessage) {
          this.$notify({
            title: '錯誤',
            message: response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
        } else {
          vm.items = response.data.Data;
        }
        this.screen.close();
      });
    },
    openModal(isNew, item) {
      if (isNew) {
        this.editRole = {};
        this.isNew = true;
      } else {
        this.editRole = item;
        this.isNew = false;
      }
    },
    openRemove(item) {
      this.removePerson = item;
    },
    openDeleteRole(role) {
      this.removeRole = role;
    },
    editRoleName(name) {
      let url = `${window.BaseUrl.api}/role/${this.editRole.Id}`;
      let httpMethod = 'put';

      // console.log('id', this.editRole.Id)

      if (this.isNew) {
        url = `${window.BaseUrl.api}/role`;
        httpMethod = 'post';
      }

      this.updateLoading(true);
      this.$http[httpMethod](url, JSON.stringify(name)).then((response) => {
        console.log(response);

        this.getAllData();
        this.updateLoading(false);
      });
    },
    choiceTarget(role, item) {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/role/user`;

      this.choiceInfo = item;

      // console.log(role)
      const params = {
        role,
      };

      this.$http.get(url, { params }).then((response) => {
        if (response.data.ErrorMessage) {
          this.$notify({
            title: '錯誤',
            message: response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
        } else {
          this.persons = response.data.Data;
        }
        this.screen.close();
      })

        .catch((error) => {
          this.$notify({
            title: '錯誤',
            message: error.response.data.ErrorMessage,
            type: 'error',
            duration: 0,
          });
          this.screen.close();
        });
    },
    deletePerson(userId) {
      // const url = `${window.BaseUrl.api}/auth/deleteuser/${this.choiceInfo.Id}`;
      const url = `${window.BaseUrl.api}/user/UserRoles`;

      const params = {
        roleId: this.choiceInfo.Id,
        userId,
      };
      // console.log('delete', params)

      this.updateLoading(true);
      this.$http.delete(url, { params }).then((response) => {
        console.log(response);
        this.choiceTarget(this.choiceInfo.Id, this.choiceInfo);
        this.$store.dispatch('alertMessageModule/updateMessage', {
          message: '成功刪除人員',
          status: 'success',
        });
        this.updateLoading(false);
      });
    },
    getAllPerson(name, role, item) {

      this.editRole = item;
      this.getPerson();
      this.getSetting();
      this.getOption();

      this.choiceTarget(role, item); // 取得之前人員加到右邊人員選擇

      this.choiceRole = name; // radio 變換
    },
    batchPerson(persons) {
      //  批次新增人員至角色
      const url = `${window.BaseUrl.api}/role/batch/?role=${this.choiceRole}`;

      const list = persons.map((person) => {

        if(this.editRole.Name!=='面試人員'){
          const emp = person.EmpNo.substring(3, 8);

          return `${person.CompId}${emp}`;
        }else{

          return `${person.CompId}${person.EmpNo}`;
        }
      });



      this.updateLoading(true);
      this.$http.post(url, list).then((response) => {
        console.log(response);

        this.choiceTarget(this.choiceInfo.Id, this.choiceInfo);
        this.$store.dispatch('alertMessageModule/updateMessage', {
          message: '成功新增人員',
          status: 'success',
        });
        this.updateLoading(false);
      });
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const i in this.items) {
          this.selected.push(this.items[i].id);
        }
      }
    },
    deleteRole() {
      const url = `${window.BaseUrl.api}/role/${this.removeRole.Id}`;

      // let params = {
      //     roleId: this.choiceInfo.Id,
      //     userId
      // }
      // console.log('delete', params)

      this.updateLoading(true);
      this.$http
        .delete(url)
        .then((response) => {
          console.log(response);
          // this.choiceTarget(this.choiceInfo.Id, this.choiceInfo)
          this.$store.dispatch('alertMessageModule/updateMessage', {
            message: '成功刪除角色',
            status: 'success',
          });
          this.updateLoading(false);
          this.getAllData();
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },
  },
  created() {
    this.getAllData();
  },
};
</script>
